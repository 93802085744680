export class CovenantTask {
    taskId: number;
    covenantId: number;
    covenantTypeId: number;
    covenantTitle: string;
    loanId: number;
    loanTitle: string;
    regionId: number;
    clientId: number;
    clientName: string;
    deadLineDate: string;
    status: string;
    assignedTo: string;
    taskReviewStatus: number = 0;

    fundId: number;
    fundName: string;
    firstApprovedBySatatus: number;
    secondApprovedByStatus: number;

    setProperties(
        taskId: number,
        covenantId: number,
        covenantTypeId: number,
        covenantTitle: string,
        loanId: number,
        loanTitle: string,
        regionId: number,
        clientId: number,
        clientName: string,
        deadLineDate: string,
        status: string,
        assignedTo: string,
        taskReviewStatus: number,
        fundId: number,
        fundName: string,
        firstApprovedBySatatus: number,
        secondApprovedByStatus: number
        ) {
        this.taskId = taskId;
        this.covenantId = covenantId;
        this.covenantTypeId = covenantTypeId;
        this.covenantTitle = covenantTitle;
        this.loanId = loanId;
        this.loanTitle = loanTitle;
        this.regionId = regionId;
        this.clientId = clientId;
        this.clientName = clientName;
        this.deadLineDate = deadLineDate;
        this.status = status;
        this.assignedTo = assignedTo;
        this.taskReviewStatus = taskReviewStatus;
        this.fundId = fundId;
        this.fundName = fundName;
        this.firstApprovedBySatatus = firstApprovedBySatatus;
        this.secondApprovedByStatus = secondApprovedByStatus;
        }

    copyProperties(paramTask: CovenantTask) {

        this.setProperties(paramTask.taskId,
            paramTask.covenantId,
            paramTask.covenantTypeId,
            paramTask.covenantTitle,
            paramTask.loanId,
            paramTask.loanTitle,
            paramTask.regionId,
            paramTask.clientId,
            paramTask.clientName,
            paramTask.deadLineDate,
            paramTask.status,
            paramTask.assignedTo,
            paramTask.taskReviewStatus,
            paramTask.fundId,
            paramTask.fundName,
            paramTask.firstApprovedBySatatus,
            paramTask.secondApprovedByStatus
        );
    }
};
