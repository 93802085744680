import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Client } from 'src/app/models/Client.model';
// create your class that extends the angular validator class
export class CDMClientValidator extends Validators {
    static validate = (clients: Array<Client>) => {
        return (control: FormControl) => {
            if (typeof control.value !== 'object') {
                return { invalid_client: true };
            }
            else {
                //validate against clients...
                if (!_.filter(clients, function (c: Client) { return !c.clientId == control.value.clientId; }))
                    return { invalid_client: true };
                else
                    return null;
            }
        }
    }
}

