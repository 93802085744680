import { Component, NgZone, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Adal6Service } from 'adal-angular6';
import {
  CommonConfirmationDialog,
  ConfirmationDialogData
} from 'src/app/common/common.confirmation.dialog.component';

import { AuthService } from './shared/auth.service';
import { MatDialog } from '@angular/material';
import { ComponentCommunication } from './shared/component-communication.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions, AuthState } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { IframeDetectionService } from './shared/iframe-detection.service'; 

const config = {
  //tenantId: environment.tenantId,
  tenant: environment.tenant + '/' + environment.authnPolicy,
  instance: environment.instance,
  clientId: environment.clientId,
  redirectUri: window.location.origin + '/',
  postLogoutRedirectUri: window.location.origin + '/',
  api_version: '2.0',
  extraQueryParameter: 'scope=openid&prompt=login' + '&p=' + environment.authnPolicy,
  cacheLocation: 'localStorage'
};

const oktaconfig: OktaAuthOptions = {
  issuer: environment.OktaDomain,
  clientId: environment.OktaClientId
};

const authClient: OktaAuth = new OktaAuth(oktaconfig);

const MINUTES_UNITL_AUTO_LOGOUT = environment.minutesUntilAutoLogout // in Minutes
const CHECK_INTERVALL = 1000 // in ms


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Covenant Diary Management';
  lastAction: number;
  loggedOutPopupCall: boolean = false;
  isResetAutoLogoutCall: boolean = false;
  public isAuthenticated: Observable<boolean>;
  public isOktaAuthenticated: boolean = false;

  constructor(/*private toastr: ToastsManager, 
    vRef: ViewContainerRef, */
    private adalService: Adal6Service,
    private ngZone: NgZone,
    private auth: AuthService,
    public dialog: MatDialog,
    public CompCommunication: ComponentCommunication,
    public authStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private iframeDetectionService: IframeDetectionService 
  ) {
   //this.toastr.setRootViewContainerRef(vRef);

    if (environment.apiUrl.indexOf("localhost") !== -1) {
      localStorage.setItem("IsLocalhost", "true");
    }

    if (environment.IsOktaAuthentication === "true" && ((this.oktaAuth == null) || (!this.oktaAuth.isAuthenticated()))) {
        this.oktaAuth.signInWithRedirect({ originalUri: '/' });
    }
    else {
      this.adalService.init(config);
    }

    localStorage.setItem("IsLogoutFromSystem", JSON.stringify(false));
    let isUserAllowed = true;
    if (localStorage.getItem("IsUserAllowed") != null && localStorage.getItem("IsUserAllowed") == "false") {
      isUserAllowed = false;
    }

    if (isUserAllowed) {
      this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': false });
    } else {
      this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': true });
    }

    this.lastAction = Date.now();
    this.checkAutoLogOut();
    this.initAutoLogOutListener();
    this.initAutoLogOutInterval();
  }

  // Call the Iframe detection service on component init
  ngOnInit() {
  
    this.iframeDetectionService.handleIframe();
  }

  initAutoLogOutListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.resetAutoLogOut());
      document.addEventListener('keypress', () => this.resetAutoLogOut());
    });
  }

  initAutoLogOutInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        //this.paramLoggingSvc.logDebug("checking ....");
        this.checkAutoLogOut();
      }, CHECK_INTERVALL);
    })
  }

  resetAutoLogOut() {
    this.lastAction = Date.now();
  }

  checkAutoLogOut() {
    let isLogoutFromSystem = JSON.parse(localStorage.getItem('IsLogoutFromSystem'));
    if (isLogoutFromSystem === true) {
      if (environment.IsOktaAuthentication === "true") {
        this.oktaAuth.signOut();
      }
      else {
        this.adalService.logOut();
      }
      return;
    }

    let isResetAutoLogout = JSON.parse(localStorage.getItem('IsResetAutoLogout'));
    if (isResetAutoLogout === true) {
      if (this.isResetAutoLogoutCall === false) {
        this.resetAutoLogOut();
        this.dialog.closeAll();
        this.isResetAutoLogoutCall = true;
        return;
      }
    }
    else {
      this.isResetAutoLogoutCall = false;
    }

    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (isTimeout && this.auth.CurrentUser.isAuthenticated && !this.loggedOutPopupCall) {
        this.loggedOutPopupCall = true;
        this.isResetAutoLogoutCall = false;
        localStorage.removeItem("IsResetAutoLogout");
        const dialogRef = this.dialog.open(CommonConfirmationDialog, {
          width: '650px',
          data: <ConfirmationDialogData>{
            title: `Inactivity Logout`,
            description: `The system is going to log then out due to inactivity.`,
            dialogData: "InactivityLogout"
          }
        });

        dialogRef.afterClosed().subscribe((confirmmation: ConfirmationDialogData) => {
          if (confirmmation != undefined && confirmmation != null) {
            if (confirmmation.isOkClick) {
              localStorage.setItem("IsResetAutoLogout", JSON.stringify(true));
              this.resetAutoLogOut();
              this.loggedOutPopupCall = false;
              this.isResetAutoLogoutCall = true;
            }
            else {
              localStorage.removeItem("IsResetAutoLogout");
              localStorage.setItem("IsLogoutFromSystem", JSON.stringify(true));
              if (environment.IsOktaAuthentication === "true") {
                this.oktaAuth.signOut();
              }
              else {
                this.adalService.logOut();
              }
            }
          } else {
            this.loggedOutPopupCall = false;
          }
        });
      }
    });
  }
}

