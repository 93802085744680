﻿//Common spinnner service to spin till data is loded.

import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private subject = new Subject<boolean>();
    private keepAfterRouteChange = false;
    constructor(private router: Router) {
        // hide spinner on route change unless 'keepAfterRouteChange' flag is true
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // hide spinner
                    this.hide();
                }
            }
        });
    }

    getSpinner(): Observable<any> {
        return this.subject.asObservable();
    }

    show(keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<boolean>true);
    }

    hide() {
        this.subject.next();
    }
}
