import { Component, AfterViewInit, OnInit, Inject } from '@angular/core';
import { UserService } from '../shared/user.service';
import { AuthzUserInfo } from '../models/AuthzUserInfo.model';
//import { Role } from 'app/shared/models/role.model'
//import { LookupService } from 'app/shared/lookup.service'
//import { ADUser } from '../shared/ADUser.model';
import { AuthService } from '../shared/auth.service';
import { Adal6Service } from 'adal-angular6';
import { Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './navbar.component.html',
    styleUrls: [`./navbar.component.scss`]
})

export class NavBarComponent implements OnInit {
    searchTerm: '';
    isAdmin = false;
    isMasterUser = false;
    isCCDirector = false;
    //currentUser: User;
    //roles: Role[];
    adminRole: string;
    ccdirectorRole = "CC Director";
    //CCDirector: Role;
    //admin: Role;
    //public currentADUser: ADUser;
    public user: string;

    constructor(private adalService: Adal6Service,
        /*private userService: UserService,
         private lookupService: LookupService,*/
        public authService: AuthService,
        private router: Router,
        public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {

    }

    ngOnInit() {

        console.log("Inside Navbar ngOnInit()");
        
        this.authService.loginSubject.subscribe(x => {
            if (x) {
                console.log("Inside authService.loginSubject.subscribe");
                
                this.isAdmin = this.authService.isAdmin;
                this.isMasterUser = this.authService.isMasterUser;
                //this.isCCDirector = this.authService.isCCDirector;
            }
        });
    }

    init() {
        this.adminRole = "Administrator";
    }

    checkForRoleAccess() {
    }

    onClick() {
        console.log("Navbar clicked!");
    }

    logout(): void {
        localStorage.setItem("IsLogoutFromSystem", JSON.stringify(true));
        if (environment.IsOktaAuthentication === "true") {
            this.oktaAuth.signOut();
        }
        else {
            this.adalService.logOut();
        }
    };

    goToCovenant(covenant = 'new') {
        //window.open(this.router.createUrlTree([`adhoctask/${0}/${covenant}`]).toString(), '_blank');
    }
}
