import { environment } from '../../environments/environment';
import { NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';

export class LoggerMonitor implements NGXLoggerMonitor {
  onLog(log: NGXLogInterface) {
    console.log("CDMLoggerMonitor", log);
    this.logToServer(log);
  }

  logToServer(log: any) {
    //var xhr = new XMLHttpRequest();
    //var targetUrl = `${environment.apiUrl}/api/APIHome`;
    //xhr.open("POST", targetUrl, true);
    //xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //xhr.send(JSON.stringify({
      //"message": "CDMLoggerMonitor - " + log.message,
      //"args": log.additional,
      //"type": log.level
    //}));
  }
}
