import { Component, Inject, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

export interface ConfirmationDialogData {
    title: string,
    description: string,
    isCancelClick: boolean,
    isOkClick: boolean,
    dialogData: any
}

@Component({
    selector: 'common-confirmation-dialog',
    styleUrls: ['./common.confirmation.dialog.component.scss'],
    templateUrl: './common.confirmation.dialog.component.html',
})
export class CommonConfirmationDialog {
    yesButtonText = 'Yes';
    noButtonText = 'Cancel';
    timeRemainingMessage = '';
    constructor(
        private dialogRef: MatDialogRef<CommonConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
        private router: Router, public ref: ChangeDetectorRef
    ) {
        this.router.events.subscribe((val) => {
            if (!this.dialogRef) return;
            this.dialogRef.close();
        });

        if (this.data.dialogData == 'InactivityLogout') {
            let timeLeft = 60;
            this.yesButtonText = 'Keep me logged in';
            this.noButtonText = 'Log me out';
            this.timeRemainingMessage = "Time Remaining: " + timeLeft + " Second";
            setInterval(() => {
                if(timeLeft > 0) {
                    timeLeft--;
                    this.timeRemainingMessage = "Time Remaining: " + timeLeft + " Second";
                } else {
                    this.onNoClick();
                }
            }, 1000);
        }
    }

    ngOnInit() {
    }

    private setOkCancelStatus(isOkClicked: boolean) {
        this.data.isOkClick = isOkClicked;
        this.data.isCancelClick = !isOkClicked;
    }

    onNoClick(): void {
        this.setOkCancelStatus(false);
        this.dialogRef.close(this.data);
    }

    onOkClick(): void {
        this.setOkCancelStatus(true);
        this.dialogRef.close(this.data);
    }

    /*

    We can then use the dialog reference to subscribe to the afterClosed() 
    observable, which will emit a value containing the output data passed to 
    dialogRef.close().

    dialogRef.afterClosed().subscribe(
        data => console.log("Dialog output:", data)
    );    
    */
}
