import { UserNDetails } from './UserNDetails.model';
import { Client } from './Client.model';

export class LegalEntity {
    legalEntityId: number;
    legalEntityCode: string;
    legalEntityName: string;
    receivingEmails: string;
    users: Array<LegalEntityUser>;
    clients: Array<LegalEntityCleint>

    setProperties(
        legalEntityId: number,
        legalEntityCode: string,
        legalEntityName: string,
        receivingEmails: string,
        users: Array<LegalEntityUser>,
        clients: Array<LegalEntityCleint>
    ) {

        this.legalEntityId = legalEntityId;
        this.legalEntityCode = legalEntityCode;
        this.legalEntityName = legalEntityName;
        this.receivingEmails = receivingEmails;
        this.users = users;
        this.clients = clients;
    }

    copyProperties(paramUserRole: LegalEntity) {

        this.setProperties(paramUserRole.legalEntityId,
            paramUserRole.legalEntityCode,
            paramUserRole.legalEntityName,
            paramUserRole.receivingEmails,
            paramUserRole.users,
            paramUserRole.clients,
        );
    }
};

export class LegalEntityUser {
    legalEntityUserId: number;
    legalEntityId: number;
    userNumberId: number;
    user: UserNDetails;

    setProperties(
        legalEntityUserId: number,
        legalEntityId: number,
        userNumberId: number,
        user: UserNDetails
    ) {

        this.legalEntityUserId = legalEntityUserId;
        this.legalEntityId = legalEntityId;
        this.userNumberId = userNumberId;
        this.user = user;
    }

    copyProperties(paramLegalEntityUser: LegalEntityUser) {

        this.setProperties(paramLegalEntityUser.legalEntityUserId,
            paramLegalEntityUser.legalEntityId,
            paramLegalEntityUser.userNumberId,
            paramLegalEntityUser.user
        );
    }
}

export class LegalEntityCleint {
    legalEntityClientId: number;
    legalEntityId: number;
    clientId: number;
    client: Client;

    setProperties(
        legalEntityClientId: number,
        legalEntityId: number,
        clientId: number,
        client: Client
    ) {

        this.legalEntityClientId = legalEntityClientId;
        this.legalEntityId = legalEntityId;
        this.clientId = clientId;
        this.client = client;
    }

    copyProperties(paramLegalEntityCleint: LegalEntityCleint) {

        this.setProperties(paramLegalEntityCleint.legalEntityClientId,
            paramLegalEntityCleint.legalEntityId,
            paramLegalEntityCleint.clientId,
            paramLegalEntityCleint.client
        );
    }
}