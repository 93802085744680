import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
//import { User } from './models/user.model';
import { AuthzUserInfo } from '../models/AuthzUserInfo.model'
import { BaseService } from "./baseService";
import { Adal6Service } from 'adal-angular6';

import { environment } from '../../environments/environment';
import { LoggingService } from '../shared/logging.service';


@Injectable()
export class UserService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService/*, paramAdalService: Adal6Service*/) {
    super(paramHttpClient, paramLoggingSvc);
    //this.adalService = paramAdalService;

    this.paramLoggingSvc.logDebug("Inside \"UserService\" constructor");
  }

  getUserByLoginId(userLoginId: string): Observable<any> {
    
    var targetURL = `${environment.apiUrl}/api/UserAuthorization`;
    return this.getAndObserve(targetURL);
  }

  logoutUser(): Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserAuthorization/LogoutUser`;
    return this.putAndObserve(targetURL, null);
  }

  getUserPreference(userLoginId: string): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/UserAuthorization/UserPreferences`;
    return this.getAndObserve(targetURL);
  }
}
