import { FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { User } from 'src/app/models/User.model';

// create your class that extends the angular validator class
export class CDMUserValidator extends Validators {
    static validate = (users: Array<User>) => {
        return (control: FormControl) => {
            if (typeof control.value !== 'object') {
                return { invalid_user: true };
            }
            else {
                //validate against clients...
                if (!_.filter(users, function (c: User) { return !c.userNumberId == control.value.userNumberId; }))
                    return { invalid_user: true };
                else
                    return null;
            }
        }
    }
}



