import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './navbar.component';
//import { UserService } from 'app/administration/user/user.service'
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
//import { AuthService } from '../shared/auth.service';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        BrowserModule
    ],
    declarations: [
        NavBarComponent
    ],
    providers: [
        //UserService
    ],
    exports: [NavBarComponent]
})
export class NavBarModule { }

