import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
import { environment } from '../../environments/environment';
import { BaseService } from './../shared/baseService';
// import { SearchCriteria } from '../models/SearchCriteria.model';
 import { ResultSet } from '../models/ResultSet.model';
// import { Vendor } from '../models/Vendor.model';
import { LoggingService } from '../shared/logging.service';
import { Region } from '../models/Region.model';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {
    super(paramHttpClient, paramLoggingSvc);
  }

  getRegions(): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Region/Regions`;
    return this.getAndObserve(targetURL);
  }
  getRegionsSortable(sortCriteria: string): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Region/Regions`;
    const _params = new HttpParams().
      set('direction', sortCriteria);
    return this.getReqAndObserve(targetURL, _params);
  }

  getRegionSearched(sortCriteria: string , name: string): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Region/Regions`;
    const _params = new HttpParams().
      set('direction', sortCriteria).
      set('query', name);
    return this.getReqAndObserve(targetURL, _params);
  }

  getActiveRegions(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Region/GetAllActiveRegions`;
    return this.getAndObserve(targetURL);
  }
}
