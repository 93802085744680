import {
  Component, ViewChild, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef, OnInit
} from '@angular/core';
import {
  startOfDay, startOfMonth, startOfWeek, endOfWeek, endOfDay, subDays, addDays, endOfMonth,
  isSameDay, isSameMonth, addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView,
  CalendarViewPeriod, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent
} from 'angular-calendar';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { CovenantTask } from '../models/CovenantTask.model';
import { CovenantService } from 'src/app/http-services/covenant.service';
import { RegionService } from 'src/app/http-services/region.service';
import { SpinnerService } from 'src/app/shared/spinner.service';
import { Router } from '@angular/router';
import { ElementRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { getToday } from '@progress/kendo-angular-dateinputs/dist/es2015/util';

const colors: any = {
  grey: {
    primary: '#808080',
    secondary: '#99A3A4'
  },
  blue: {
    primary: '#0000FF',
    secondary: '#5DADE2'
  },
  orange: {
    primary: '#FFA500',
    secondary: '#F7DC6F'
  },
  black: {
    primary: '#000000',
    secondary: '#566573'
  },
  red: {
    primary: '#FF0000',
    secondary: '#E74C3C'
  }
};

interface ClientObject { clientId: number, clientName: string };

interface FundObject { fundId: number, fundName: string };

@Component({
  selector: 'my-calendar-component',
  styleUrls: ['./my-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './my-calendar.component.html'
})
export class MyCalendarComponent implements OnInit {
  @ViewChild('filterType') filterType: ElementRef;
  view: CalendarView = CalendarView.Month;
  period: CalendarViewPeriod;
  CalendarView = CalendarView;
  covenantTasks: CovenantTask[] = [];
  groupedCovenant: any;
  regions: any;
  clientType: ClientObject[] = [];
  covenantType: any;
  activeMonth: number;
  isChecked: boolean = true;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  form: FormGroup;
  objectKeys = Object.keys;
  regionSelected: number = 0;
  clientSelected: number = 0;
  covenantTypeSelected: number = 0;
  taskStatusSelected: number = 0;
  covenantStatus: any[];
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;

  fundType: FundObject[] = [];
  fundSelected: number = 0;
  approverSelected: number = 0;

  actions: CalendarEventAction[] = [
    {
      label: '',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //this.handleEvent('Edited', event);
        //alert("This is a covenant task");
      }
    }
  ];

  constructor(private modal: NgbModal,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private spinnerService: SpinnerService,
    private covenantService: CovenantService,
    private regionService: RegionService) {
    this.resetDefaultFilters();
  }

  get formCtrls() { return this.form.controls; }

  ngOnInit() {
    this.form = this.formBuilder.group({
      Covenants: new FormArray([]),
      Clients: [0, Validators.required]
    });

    this.activeMonth = new Date().getMonth();
    this.addCheckboxes();
    this.loadRegions();
    this.loadCovenantTypes();
    this.formCtrls.Clients.setValue('All Clients', { onlySelf: true });
    let start = moment(startOfMonth(new Date())).format('LL');
    let end = moment(endOfMonth(new Date())).format('LL');
    setTimeout(() => {
      this.loadCovenantTasks(start, end);
    }, 500);
  }

  resetDefaultFilters() {
    this.covenantStatus = [{ 'id:': 1, 'value': 'NO_REMINDER', 'checked': true, 'text': 'No Reminder Email Sent', 'color': 'grey' },
    { 'id:': 2, 'value': 'REMINDER', 'checked': true, 'text': 'Reminder Email Sent', 'color': 'blue' },
    { 'id:': 3, 'value': 'DUE_5_DAYS', 'checked': true, 'text': 'Due in 5 days', 'color': 'orange' },
    { 'id:': 4, 'value': 'PAST_DUE', 'checked': true, 'text': 'Past Due', 'color': 'red' },
    { 'id:': 5, 'value': 'DEFAULT', 'checked': true, 'text': 'Default', 'color': 'black' }];

    this.regionSelected = 0;
    this.clientSelected = 0;
    this.covenantTypeSelected = 0;
    this.taskStatusSelected = 0;

    this.fundSelected = 0;
    this.approverSelected = 0;
  }

  beforeViewRender(
    event:
      | CalendarMonthViewBeforeRenderEvent
  ) {
    this.period = event.period;
    this.cdr.detectChanges();
  }

  getColor(status) {
    let color: any;
    switch (status) {
      case "NO_REMINDER":
        color = colors.grey;
        break;
      case "REMINDER":
        color = colors.blue;
        break;
      case "DUE_5_DAYS":
        color = colors.orange;
        break;
      case "PAST_DUE":
        color = colors.red;
        break;
      case "DEFAULT":
        color = colors.black;
        break;
    }

    return color;
  }

  groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  loadCovenantTypes() {
    this.covenantService.fetchCovenantTypes().
      subscribe((response: any) => {
        this.covenantType = response.body.covenantTypeList;
      });
  }

  loadRegions() {
    this.regionService.getRegions().
      subscribe((response: any) => {
        this.regions = response.body.results;
      });
  }

  loadCovenantTasks(startDate, endDate) {
    this.spinnerService.show();
    this.covenantService.getCovenantTasks(startDate, endDate).
      subscribe((response: any) => {
        this.clientType = [];

        this.fundType = [];

        response.body.forEach((element: CovenantTask) => {
          let covenantTask: CovenantTask = new CovenantTask();
          covenantTask.copyProperties(element);
          this.covenantTasks.push(covenantTask);

          //Update Clients
          const checkClientId = obj => obj.clientId === covenantTask.clientId;
          if (covenantTask.clientId !== 0 && !(this.clientType.some(checkClientId))) {
            let clientObject: ClientObject = { clientId: covenantTask.clientId, clientName: covenantTask.clientName };
            this.clientType.push(clientObject);
          }

          // Bind fund
          const checkFundId = obj => obj.fundId === covenantTask.fundId;
          if (covenantTask.fundId !== 0 && !(this.fundType.some(checkFundId))) {
            let fundObject: FundObject = { fundId: covenantTask.fundId, fundName: covenantTask.fundName };
            this.fundType.push(fundObject);
          }

          //Load Events
          this.loadEvents();
        });

        this.spinnerService.hide();
        this.refresh.next();
      });
  }

  private addCheckboxes() {
    console.log("Covenant Type", this.covenantType);
    for (let i = 0; i < 5; i++) {
      const control = new FormControl();
      //control.setValue(key);
      (this.form.controls.Covenants as FormArray).push(control);
    }
  }

  selectAllTasks(event) {
    if (event.target.checked == true) {
    }
  }

  filterByStatus(event) {
    this.covenantStatus.forEach((obj) => {
      if (obj.value == event.source.value) {
        obj.checked = event.checked;
      }
    });

    this.loadEvents();
  }

  filterByRegion(event) {
    if (parseInt(event.target.value) !== 0) {
      this.regionSelected = event.target.value;
    } else {
      this.regionSelected = 0;
    }

    this.loadEvents();
  }

  filterByClient(event) {
    if (parseInt(event.target.value) !== 0) {
      this.clientSelected = event.target.value;
    } else {
      this.clientSelected = 0;
    }

    this.loadEvents();
  }

  filterByTask(event) {
    if (parseInt(event.target.value) !== 0) {
      this.covenantTypeSelected = event.target.value;
    } else {
      this.covenantTypeSelected = 0;
    }

    this.loadEvents();
  }

  filterByTaskStatus(event) {
    if (parseInt(event.target.value) !== 0) {
      this.taskStatusSelected = event.target.value;
    } else {
      this.taskStatusSelected = 0;
    }

    this.loadEvents();
  }

  loadEvents() {
    let filteredTasks: CovenantTask[] = [];
 
    this.covenantTasks.forEach((obj) => {
  
      let IstaskApproval = 0
      if (this.approverSelected == 1) {
        IstaskApproval = obj.firstApprovedBySatatus;
      }
      if (this.approverSelected == 2) {
        IstaskApproval = obj.secondApprovedByStatus;
      }

      if (this.regionSelected != 0 && obj.regionId != this.regionSelected) {
        return;
      }

      if (this.clientSelected != 0 && obj.clientId != this.clientSelected) {
        return;
      }

      if (this.covenantTypeSelected != 0 && obj.covenantTypeId != this.covenantTypeSelected) {
        return;
      }

      if (this.taskStatusSelected != 0 && obj.taskReviewStatus != this.taskStatusSelected) {
        return;
      }

      if (this.fundSelected != 0 && obj.fundId != this.fundSelected) {
        return;
      }

      if (this.approverSelected != 0 && IstaskApproval != this.approverSelected) {
        return;
      }
      // if(this.approverSelected != 0 && obj.secondApprovedByStatus != this.approverSelected) {
      //   return;
      // }

      let isStatusDisplay: boolean = true;
      this.covenantStatus.forEach((status) => {
        if (!status.checked && obj.status == status.value) {
          isStatusDisplay = false;
          return;
        }
      });

      if (!isStatusDisplay) {
        return;
      }

      filteredTasks.push(obj);
    });

    this.events = [];
    filteredTasks.forEach((obj) => {
      //Set Title
      let title: string = '';
      if (obj.clientId !== 0) {
        title = "Client: " + obj.clientName + " > " + "Loan: " + obj.loanTitle + " > " + "Covenant: " + obj.covenantTitle
      } else { title = "Loan: " + obj.loanTitle + " > " + "Covenant: " + obj.covenantTitle }

      let event = {
        id: obj.taskId,
        start: startOfDay(new Date(obj.deadLineDate)),
        title: title,
        color: this.getColor(obj.status),
        actions: this.actions,
        allDay: true
      };

      this.events.push(event);
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    let targetCovenant = this.covenantTasks.find((task) => { return task.taskId === event.id; });
    window.open(this.router.createUrlTree([`covenant/${targetCovenant.loanId}/${targetCovenant.covenantId}`]).toString(), '_blank');
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.green,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.resetDefaultFilters();
    console.log("View Date", this.viewDate);
    if (view === "month" && this.view === "week") {
      this.covenantTasks = [];
      this.events = [];
      this.view = view;
      let dates = this.getStartAndEndDate(this.viewDate);
      this.loadCovenantTasks(dates[0], dates[1]);
    }
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getStartAndEndDate(currentDate) {
    let startDate, endDate;
    if (this.view === "month") {
      startDate = moment(startOfMonth(new Date(currentDate))).local().format('LL');
      endDate = moment(endOfMonth(new Date(currentDate))).local().format('LL');
    } else if (this.view === "week") {
      startDate = moment(startOfWeek(new Date(currentDate))).local().format('LL');
      endDate = moment(endOfWeek(new Date(currentDate))).local().format('LL');
    }
    return [startDate, endDate];
  }

  changeMonthWeekEvent(event) {
    this.resetDefaultFilters();
    this.covenantTasks = [];
    this.events = [];
    let dates = this.getStartAndEndDate(event);
    //this.loadCovenantTasks(this.period.start.toDateString(), this.period.end.toDateString());
    this.loadCovenantTasks(dates[0], dates[1]);
  }


  filterByFund(event) {
    if (parseInt(event.target.value) !== 0) {
      this.fundSelected = event.target.value;
    } else {
      this.fundSelected = 0;
    }

    this.loadEvents();
  }

  filterByApprover(event) {
    if (parseInt(event.target.value) !== 0) {
      this.approverSelected = event.target.value;
    } else {
      this.approverSelected = 0;
    }

    this.loadEvents();
  }


}