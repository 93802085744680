import { Injectable,Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CommonAlertDialog, AlertDialogData } from '../common/common.alert.dialog.component';
import { Adal6Service } from 'adal-angular6';
import { SpinnerService } from './spinner.service';
import { WSO2Service } from '../shared/wso2.service';
import { environment } from '../../environments/environment';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';


@Injectable({
    providedIn: 'root'
})
export class ErrorDialogService {
    constructor(public dialog: MatDialog,
        public dialogRef: MatDialogRef<CommonAlertDialog>,
        private adalService: Adal6Service,
        private spinnerService: SpinnerService,
        private wso2Service: WSO2Service,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

    openDialog(error: any): void {
        this.spinnerService.hide();

        let timeOutPeriod = error.status == 401 ? 1000 : 0;

        setTimeout(() => {
            const dialogRef = this.dialog.open(CommonAlertDialog, {
                data: this.getQualifiedErrorMessage(error)
            });

            dialogRef.afterClosed().subscribe((error: any) => {
                if (error.status == 401)
                {
                    if ((environment.IsOktaAuthentication === "false") && (!this.adalService.userInfo.authenticated || environment.apiUrl.indexOf("localhost") !== -1)) {
                        this.adalService.login();
                    }
                    else if (environment.IsOktaAuthentication === "true" && environment.apiUrl.indexOf("localhost") === -1) {
                        this.oktaAuth.signInWithRedirect({ originalUri: '/' });
                    }
                    else if (environment.apiUrl.indexOf("localhost") === -1) {
                        window.location.reload();
                    }
                }                    
            });
        }, timeOutPeriod);
    }

    private getQualifiedErrorMessage(error: any) {
        if (!error)
            return;
        if (!error.status)
            error.status = 0;
        let data = {
            reason: error.status == 0 ? error.message : (
                error && error.error ? error.error.reason : error.statusText),
            status: error.status
        };
        var msg = <AlertDialogData>{ title: '', description: '' };
        switch (data.status) {
            case 400:
                msg = <AlertDialogData>{
                    title: 'Bad Request !',
                    description: 'The request could not be understood by the server due to malformed syntax..',
                    dialogData: data
                };
                break;
            case 401:
                this.wso2Service.getWSO2RefreshToken();
                if (environment.IsOktaAuthentication==="false" && this.adalService.userInfo.authenticated && environment.apiUrl.indexOf("localhost") === -1) {
                    msg = <AlertDialogData>{
                        title: 'Sesson timed out !',
                        description: 'If message was received in error please refresh and confirm login status. <br/> If error continues contact your system administrator.',
                        dialogData: data
                    };
                }
                else if(environment.IsOktaAuthentication==="true" && environment.apiUrl.indexOf("localhost")===-1){                    
                    msg = <AlertDialogData>{
                        title: 'Sesson timed out !',
                        description: 'If message was received in error please refresh and confirm login status. <br/> If error continues contact your system administrator.',
                        dialogData: data
                    };
                }
                else {
                    msg = <AlertDialogData>{
                        title: 'Unauthorized !',
                        description: 'You do not have permission to view this page. <br/> If message was received in error please refresh and confirm login status. <br/> If error continues contact your system administrator.',
                        dialogData: data
                    };                  
                }
                break;
            case 403:
                msg = <AlertDialogData>{
                    title: 'Forbidden !',
                    description: 'The server understood the request, but is refusing to fulfill it.',
                    dialogData: data
                };
                break;
            case 404:
                msg = <AlertDialogData>{
                    title: 'Not Found !',
                    description: 'The server has not found anything matching the Request-URI.',
                    dialogData: data
                };
                break;
            case 405:
                msg = <AlertDialogData>{
                    title: 'Method Not Allowed !',
                    description: 'The method specified in the Request-Line is not allowed for the resource identified by the Request-URI.',
                    dialogData: data
                };
                break;
            case 0:
                msg = <AlertDialogData>{
                    title: 'Oops! Something went wrong.',
                    description: `The application has encountered an unknown error.
                    <br/> If error continues contact your system administrator.`,
                    dialogData: data
                };
                break;
            default:
                msg = <AlertDialogData>{
                    title: data.reason,
                    description: data.status,
                    dialogData: data
                };

        }
        return msg;
    }
}