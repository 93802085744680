import { Injectable } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {NgxLoggerLevel} from 'ngx-logger';
import {LoggerMonitor} from './logger-monitor';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private logger: NGXLogger) {
    this.logger.registerMonitor(new LoggerMonitor())
    this.logger.updateConfig({ level: NgxLoggerLevel.DEBUG });
    this.logger.debug('Init Logging Service');
  }

  logError(message: any) {   
    this.logger.updateConfig({ level: NgxLoggerLevel.ERROR }); 
    this.logger.error(message);    
  }

  logInfo(message: any) {    
    this.logger.updateConfig({ level: NgxLoggerLevel.INFO }); 
    this.logger.info(message);    
  }

  logWarning(message: any) {    
    this.logger.updateConfig({ level: NgxLoggerLevel.WARN }); 
    this.logger.warn(message);    
  }

  logDebug(message: any) {   
    this.logger.updateConfig({ level: NgxLoggerLevel.DEBUG });  
    this.logger.debug(message);    
  }

  logTrace(message: any) {
    this.logger.updateConfig({ level: NgxLoggerLevel.TRACE }); 
    this.logger.trace(menubar);
  }
}
