import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoggingService } from '../shared/logging.service';
import { environment } from '../../environments/environment';

@Injectable()
export class WSO2Service {
   constructor(private http: HttpClient, private paramLoggingSvc: LoggingService) { }

   getWSO2Token() {
      const tokenURL = environment.wso2TokenURL;
      return this.getLoginCredentialsAccessToken(tokenURL);
   }

   getWSO2RefreshToken() {
      if (environment.apiUrl.indexOf("localhost") === -1) {
         return this.getWSO2Token().subscribe((data: any) => {
            localStorage.setItem('wso2.idtoken', data.access_token)
            //localStorage.setItem('wso2.tokensettime', Date.now().toString())
            return data.access_token;
         });
      }
   }

   private getLoginCredentialsAccessToken(tokenURL: string): Observable<any> {

      const headersList = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*', });
      var _params = new HttpParams()
         .set('grant_type', 'client_credentials')
         .set('client_id', environment.wso2ClientId)
         .set('client_secret', environment.wso2ClientSecret);

      return this.http.post<any>(tokenURL, _params, { headers: headersList })
         .pipe(catchError(this.handleErrorWSO2(tokenURL, [])));
   }

   protected handleErrorWSO2<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<any> => {
         //debugger;
         // TODO: send the error to remote logging infrastructure
         this.paramLoggingSvc.logError(error); // log to console instead

         // TODO: better job of transforming error for user consumption
         this.paramLoggingSvc.logDebug(`${operation} failed: ${error.message}`);
         return throwError(`Error: statusCode ${error.status} ${error.statusText}, ${error.error}, ${error.message}`);
         // Let the app keep running by returning an empty result.
         // return of(result as T);
      };
   }
}