import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
import { environment } from '../../environments/environment';
import { BaseService } from "./../shared/baseService";
import { ServiceProviderInfo } from '../models/ServiceProviderInfo.model';
import { ObjectIdentifier } from '../models/ObjectIdentifier.model';
import { UserNDetails } from '../models/UserNDetails.model';
import { LoggingService } from '../shared/logging.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {

    super(paramHttpClient, paramLoggingSvc);
  }

  getSPsNUsers(userLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/ServiceProviderNUsers`;
    return this.getAndObserve(targetURL);
  }
  
  getRolesNUsers(userLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation`;
    return this.getAndObserve(targetURL);
  }

  getAllUsers(userLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation/UsersNRoles`;
    return this.getAndObserve(targetURL);
  }

  getUserObjects(userLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation/UserObjects`;
    return this.getAndObserve(targetURL);
  }

  getUserByDisplayName(displayName: string)
    : Observable<any> {

    //clearTimeout(null);
    //var targetURL = `https://ppkpi-azureadservice.azurewebsites.net/api/auth/displaynamestartswith?displayname=${displayName}`;
    displayName = displayName.replace(/,/g, '%2c');
    displayName = displayName.replace(/@/g, '%40');
    var targetURL = `${environment.apiUrl}/api/SearchInternalUser/${displayName}`;
    return this.getAndObserve(targetURL);
  }

  addNewSP(serviceProvider: ServiceProviderInfo, superUserLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/ServiceProviderNUsers/BySuperUserId/` + superUserLoginId;
    return this.postAndObserve(targetURL, serviceProvider);
  }

  editSP(serviceProvider: ServiceProviderInfo, superUserLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/ServiceProviderNUsers/BySuperUserId/` + superUserLoginId;
    return this.putAndObserve(targetURL, serviceProvider);
  }

  deleteSP(spIdentifier: ObjectIdentifier, superUserLoginId: string)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/ServiceProviderNUsers/DeleteSP/BySuperUserId/` + superUserLoginId;
    return this.putAndObserve(targetURL, spIdentifier);
  }

  getUsersByNamePartSearch(namePart: string, superUserLoginId: string) : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation/SearchByNamePart/BySuperUserId/` + superUserLoginId + `?userNamePart=` + namePart;
    return this.getAndObserve(targetURL);
  }
  
  addNewUser(user: UserNDetails)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation`;
    return this.postAndObserve(targetURL, user);
  }

  editUser(user: UserNDetails)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation`;
    return this.putAndObserve(targetURL, user);
  }

  deleteUser(userIdentifier: ObjectIdentifier)
    : Observable<any> {

    var targetURL = `${environment.apiUrl}/api/UserInformation/DeleteUser`;
    return this.putAndObserve(targetURL, userIdentifier);
  }

  getUsersBySearch(search: string) : Observable<any> {
    var targetURL = `${environment.apiUrl}/api/UserInformation/GetUsersBySearch?search=` + search;
    return this.getAndObserve(targetURL);
  }

  approveUser(userIdentifier: ObjectIdentifier)
    : Observable<any> {
    var targetURL = `${environment.apiUrl}/api/UserInformation/ApproveUser`;
    return this.putAndObserve(targetURL, userIdentifier);
  }

  // added for New
  getUserWithRegion(userLoginId: string)
  : Observable<any> {

  var targetURL = `${environment.apiUrl}/api/UserInformation/UserwithRegion`;
  return this.getAndObserve(targetURL);
  //END
}

}
