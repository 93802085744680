import { Pipe, PipeTransform } from '@angular/core';
import { LegalEntityUser } from 'src/app/models/LegalEntity.model';
import * as _ from 'lodash';
import { UserNDetails } from 'src/app/models/UserNDetails.model';

@Pipe({
    name: "displayuser"
})
export class UserDisplayPipe implements PipeTransform {
    transform(value: LegalEntityUser[], ...args: any[]): string {
        let dispaly = "";
        dispaly = _.join( _.map(_.map(value, 'cdmUserInfo'), (u: UserNDetails) => { return u.firstName + " " + u.lastName }), ',');
        return dispaly;
    }
}