import { Pipe, PipeTransform } from '@angular/core';
import { LegalEntityCleint } from 'src/app/models/LegalEntity.model';
import * as _ from 'lodash';

@Pipe({
    name: "displayclient"
})
export class ClientDisplayPipe implements PipeTransform {
    transform(value: LegalEntityCleint[], ...args: any[]): string {
        let dispaly = "";
        dispaly = _.join(_.map(_.map(value, 'cdmClient'), 'name'), ',');
        return dispaly;
    }
}