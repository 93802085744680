import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { headerRouter } from './header.router';
import { NavBarModule } from '../navbar/navbar.module';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';

@NgModule({
    imports: [
        // headerRouter
        NavBarModule,
        RouterModule,
        CommonModule,
        BrowserModule
    ],
    providers: [
        AuthService
    ],
    declarations: [
        HeaderComponent
    ],
    exports: [HeaderComponent]
})
export class HeaderModule { }

