import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
import { environment } from '../../environments/environment';
import { BaseService } from "../shared/baseService";
import { Client } from '../models/Client.model';
import { SearchCriteria } from '../models/SearchCriteria.model';
import { ResultSet } from '../models/ResultSet.model';
import { RecordStatus } from '../models/RecordStatus.model';
import { LoggingService } from '../shared/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {
    super(paramHttpClient, paramLoggingSvc);
  }

  getClients(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/Client/Clients`;
    var _params = new HttpParams().
      set('id', searchCriteria.id.toString()).
      set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction).
      set('query', searchCriteria.query);

    return this.getReqAndObserve(targetURL, _params);
  }

  getClientByRegion(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/Client/ClientsByRegion/${searchCriteria.regionId}`;
    var _params = new HttpParams().
    set('id', searchCriteria.id.toString()).
    set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
    set('pageSize', searchCriteria.pageSize.toString()).
    set('pageIndex', searchCriteria.pageIndex.toString()).
    set('active', searchCriteria.active).
    set('direction', searchCriteria.direction).
    set('query', searchCriteria.query);
    return this.getReqAndObserve(targetURL, _params);
  }


  getClientEntities(client: Client): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/Client/EntityClients`;
    var _params = new HttpParams().
      set('id', client.clientId.toString()).
      set('pageSize', "20").
      set('pageIndex', "0").
      set('active', "LegalEntityId").
      set('direction', "desc").
      set('query', "");
    return this.getReqAndObserve(targetURL, _params);
  }

  deleteClient(client: Client): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Client/${client.clientId}`;
    return this.deleteAndObserve(targetURL);
  }

  archiveClient(client: Client): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Client/Archive/${client.clientId}`;
    return this.deleteAndObserve(targetURL);
  }
  editClientRecordStatus(recordStatus: Array<RecordStatus>): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Client/UpdateRecordsStatus`;
    return this.putAndObserve(targetURL, recordStatus);
  }
  addClient(client: Client): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Client`;
    return this.postAndObserve(targetURL, client);
  }

  editClient(client: Client): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Client/${client.clientId}`;
    return this.putAndObserve(targetURL, client);
  }
}
