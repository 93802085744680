import { LegalEntity } from "./LegalEntity.model";

export class Client {
    clientId: number;
    name: string;
    regionId: number;
    legalEntities: Array<LegalEntity> = new Array<LegalEntity>();
    setProperties(
        clientId: number,
        name: string,
        regionId: number
    ) {

        this.clientId = clientId;
        this.name = name;
        this.regionId = regionId;
    }

    copyProperties(paramClient: Client) {

        this.setProperties(paramClient.clientId,
            paramClient.name,
            paramClient.regionId
        );
    }
};
