﻿//Common spinner component.
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { SpinnerService } from '../../shared/spinner.service';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
    show: boolean = false;
    constructor(private spinnerService: SpinnerService, private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.spinnerService.getSpinner().subscribe((spinner: boolean) => {
            if (!spinner) {
                // hide spinner when an empty spinner is received
                this.show = false;
            } else {
                // show spinner
                this.show = true;
            }
            
            this.ref.detectChanges();
        });
    }

}
