import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoggingService } from '../shared/logging.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
  public allow = false;

  constructor(private router: Router,
    private authService: AuthService, private paramLoggingSvc: LoggingService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.checkIsUserAllowed()) {
      this.router.navigate(['']);
      return false;
    }

    this.paramLoggingSvc.logDebug(this.authService.isAdmin + '- CAN-ACTIVATE-ADMIN.');
    this.paramLoggingSvc.logDebug(this.authService.isMasterUser + '- CAN-ACTIVATE-MASTER.');
    if (this.authService.isAdmin || this.authService.isMasterUser) {
      return true;
    } else {
      const checkSession = this.checkUserSession(route, state);
      if (checkSession) {
        return true;
      } else {
        if (route.params['mode'] === 'new') {
          this.router.navigate(['my-loans']);
        } else {
          this.router.navigate(['']);
        }
        return false;
      }
    }
  }
  private checkUserSession(route, state) {
    if (localStorage.getItem('CurrentUser')) {
      const currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
      if ((currentUser.isUserAnAdmin && currentUser.userRole.userTypeTitle === 'Administrator')
        || (currentUser.isMasterUser && currentUser.userRole.userTypeTitle === 'Master User')
        || (currentUser.userRole.userTypeTitle === 'User')
        || (currentUser.userRole.userTypeTitle === 'View Only User')) {

        // console.log('route.params ', route.params['mode']);
        // console.log('route-access', state);
        if (route.params['mode'] === 'new' && (currentUser.userRole.userTypeTitle === 'User' ||
          currentUser.userRole.userTypeTitle === 'View Only User')) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private checkIsUserAllowed() {
    let isUserAllowed = localStorage.getItem('IsUserAllowed');
    if (isUserAllowed != null && isUserAllowed != undefined && isUserAllowed == "true") {
      return true;
    } else {
      return false;
    }
  }
}
