import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { BaseService } from '../shared/baseService';
import { Loan } from '../models/Loan.model';
import { ResultSet } from '../models/ResultSet.model';
import { RecordStatus } from '../models/RecordStatus.model';
import { LoanDetailsRequestBulkInsert } from '../models/LoanDetailsRequestBulkInsert.model';
import { LoggingService } from '../shared/logging.service';
import { LoanNote } from '../models/LoanNote.model';

@Injectable({
  providedIn: 'root'
})
export class LoanDetailsService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {
    super(paramHttpClient, paramLoggingSvc);
  }

  addLoan(loan: Loan): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/CreateLoan`;
    return this.postAndObserve(targetURL, loan);
  }
  updateLoanComments(loan: Loan): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/UpdateLoanComments`;
    return this.putAndObserve(targetURL, loan);
  }
  getLoan(loanId: number): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/GetLoanById/${loanId}`;
    return this.getAndObserve(targetURL);
  }

  dateFormat(userId: number): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Preference/GetPreference/${userId}`;
    return this.getAndObserve(targetURL);
  }
  currencyDetails(): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/GetCurrencyMasters`;
    return this.getAndObserve(targetURL);
  }
  approveLoan(loanId: number): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/ApproveLoan/${loanId}`;
    return this.getAndObserve(targetURL);
  }
  deleteLoan(loanId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/DeleteLoan/${loanId}`;
    return this.deleteAndObserve(targetURL);
  }

  deleteAssociation(id: Number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/ClientObject/DeleteObject/${id}`;
    return this.deleteAndObserve(targetURL);
  }

  processLoanDetailsFile(fileData: Array<any>): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/ProcessLoanDetailsFile `;
    return this.postAndObserve(targetURL, fileData);
  }

  processLoanValuationFile(fileData: Array<any>): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/ProcessLoanValuationFile `;
    return this.postAndObserve(targetURL, fileData);
  }

  onProcessBulkInsertLoanFile(loanDetailsRequestBulkInsert: LoanDetailsRequestBulkInsert): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/ProcessLoanDetailsBulkInsertFile`;
    return this.postAndObserve(targetURL, loanDetailsRequestBulkInsert);
  }

  closeLoan(loanId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/CloseLoan/${loanId}`;
    return this.putAndObserve(targetURL, null);
  }

  approveRejectCloseLoan(loanId: number, isApprove: boolean): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/ApproveRejectCloseLoan/${loanId}/${isApprove}`;
    return this.putAndObserve(targetURL, null);
  }

  createLoanNote(loanNote: LoanNote): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/CreateLoanNote`;
    return this.postAndObserve(targetURL, loanNote);
  }

  updateLoanNote(loanNote: LoanNote): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/UpdateLoanNote`;
    return this.putAndObserve(targetURL, loanNote);
  }

  getLoanNotes(loanId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/GetLoanNotes/${loanId}`;
    return this.getAndObserve(targetURL);
  }
  updateLoanAssociations(loan: Loan): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/LoanDetails/UpdateLoanAssociations`;
    return this.postAndObserve(targetURL, loan);
  }
}
