import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { LoggingService } from '../shared/logging.service';
import { Router } from '@angular/router';

@Injectable()
export class AdminOrMasterUserGuardService implements CanActivate {
  private currentUser: any;
  constructor(private router: Router,
    private authService: AuthService, private paramLoggingSvc: LoggingService) { }

  canActivate() {
    if (!this.checkIsUserAllowed()) {
      this.router.navigate(['']);
      return false;
    }

    this.paramLoggingSvc.logDebug(this.authService.isAdmin + '- CAN-ACTIVATE-ADMIN.');
    this.paramLoggingSvc.logDebug(this.authService.isMasterUser + '- CAN-ACTIVATE-MASTER.');
    if (this.authService.isAdmin || this.authService.isMasterUser) {
      return true;
    } else {
      const checkSession = this.checkUserSession();
      if (checkSession) {
        return true;
      } else {
        this.router.navigateByUrl('home');
        return false;
      }
    }
  }

  private checkUserSession() {
    if (localStorage.getItem('CurrentUser')) {
      const currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
      if ((currentUser.isUserAnAdmin && currentUser.userRole.userTypeTitle === 'Administrator')
        || (currentUser.isMasterUser && currentUser.userRole.userTypeTitle === 'Master User')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private checkIsUserAllowed() {
    let isUserAllowed = localStorage.getItem('IsUserAllowed');
    if (isUserAllowed != null && isUserAllowed != undefined && isUserAllowed == "true") {
      return true;
    } else {
      return false;
    }
  }
}
