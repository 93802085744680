import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { LoginComponent2 } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './shared/auth-guard.service';
import { AdminOrMasterUserGuardService } from './shared/admin-or-master-user-auth-guard.service';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { LoanProcessComponent } from './administration/loan-process/loan-process.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login/callback', component: OktaCallbackComponent },
  //{ path: 'home', component: HomeComponent },
  // { path: 'file-delivery', component: FileDeliveryComponent },
  // { path: 'file-submission-status', component: FileSubmissionStatusComponent },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'loan-details/:mode', loadChildren: './loan-details.module#LoanDetailsModule'},
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'covenant/:loan/:mode', loadChildren: './covenant-details.module#CovenantDetailsModule'},
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'covenant/:loan/:mode/:view', loadChildren: './covenant-details.module#CovenantDetailsModule'},
  
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'adhoctask/:loan/:mode', loadChildren: './covenant-details.module#CovenantDetailsModule'},
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'adhoctask/:loan/:mode/:view', loadChildren: './covenant-details.module#CovenantDetailsModule'},

  //{ canActivate: [AuthGuardService], path: 'loan-details/:mode', component: LoanDetailsComponent },
  //{ canActivate: [AuthGuardService], path: 'covenant/:loan/:mode', component: CovenantWholeFormComponent },
  //{ canActivate: [AuthGuardService], path: 'covenant/:loan/:mode/:view', component: CovenantWholeFormComponent },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'my-calendar', component: MyCalendarComponent },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'my-tasks', loadChildren: './my-tasks.module#MyTasksModule' },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'my-loans', loadChildren: './my-loans.module#MyLoansModule' },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'reporting', loadChildren: './reporting/reporting.module#ReportingModule' },
  { canActivate: [AdminOrMasterUserGuardService], path: 'admin/email-configuration', loadChildren: './email-settings.module#EmailSettingsModule' },
  { canActivate: [AdminOrMasterUserGuardService], path: 'admin/maintain-legal-entity', loadChildren: './client-objects.module#ClientObjectsModule' },
  { canActivate: [AdminOrMasterUserGuardService], path: 'admin/maintain-user-objects', loadChildren: './user-objects.module#UserObjectsModule' },
  { canActivate: [AdminOrMasterUserGuardService], path: 'admin/upload-loan-values', component: LoanProcessComponent },
  { canActivate: [AuthGuardService,OktaAuthGuard], path: 'user-settings', loadChildren: './user-settings.module#UserSettingsModule' },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
