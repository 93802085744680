// component-communication.service.ts
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ComponentCommunication {
  private messageSource = new BehaviorSubject({ 'hide-header': true });
  currentMessage = this.messageSource.asObservable();

  private disableMenu = new BehaviorSubject({ 'disable-menu': true });
  public disableMenuMessage = this.disableMenu.asObservable();


  private loanIdMessage = new BehaviorSubject({ 'loan-id': 0 });
  public currentLoanId = this.loanIdMessage.asObservable();

  private userDateFormat = new BehaviorSubject({ 'dateFormat': '' });
  public dateFormat = this.userDateFormat.asObservable();

  /////////// FOR RELOADING THE LOAN PAGE AFTER LOAN DELETE NOT WORKING CURRENTLY ///////////////

  //  private reloadLoanCondition = new BehaviorSubject({'reload' : false});
  //  public currentCommand = this.reloadLoanCondition.asObservable();




  //////////////// METHODS ///////////////////////////

  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  changeDisableMenuMessage(message: any) {
    this.disableMenu.next(message);
  }

  changeLoanId(message: any) {
    this.loanIdMessage.next(message);
  }

  changeDateFormat(message: any) {
    this.userDateFormat.next(message);
  }

}
