import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
import { environment } from '../../environments/environment';
import { BaseService } from "../shared/baseService";
import { SearchCriteria } from '../models/SearchCriteria.model';
import { ResultSet } from '../models/ResultSet.model';
import { LegalEntity } from 'src/app/models/LegalEntity.model';
import { RecordStatus } from '../models/RecordStatus.model';
import { LoggingService } from '../shared/logging.service';

@Injectable({
  providedIn: 'root'
})
export class LegalEntityService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {

    super(paramHttpClient, paramLoggingSvc);
  }

  getLegalEntities(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity`;
    var _params = new HttpParams().
      set('id', searchCriteria.id.toString()).
      set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction).
      set('query', searchCriteria.query);

    return this.getReqAndObserve(targetURL, _params);
  }
  getLegalEntityClients(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/ClientEntities`;
    var _params = new HttpParams().
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction).
      set('query', searchCriteria.query);

    return this.getReqAndObserve(targetURL, _params);
  }
  getClients(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/Clients`;
    var _params = new HttpParams().
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction);

    return this.getReqAndObserve(targetURL, _params);
  }
  getUsers(searchCriteria: SearchCriteria): Observable<ResultSet> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/Users`;
    var _params = new HttpParams().
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction);

    return this.getReqAndObserve(targetURL, _params);
  }

  addLegalEntity(legalEntity: LegalEntity): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity`;
    return this.postAndObserve(targetURL, legalEntity);
  }

  editLegalEntity(legalEntity: LegalEntity): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/${legalEntity.legalEntityId}`;
    return this.putAndObserve(targetURL, legalEntity);
  }
  editLegalEntityRecordStatus(recordStatus: Array<RecordStatus>): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/UpdateRecordsStatus`;
    return this.putAndObserve(targetURL, recordStatus);
  }
  deleteLegalEntity(legalEntity: LegalEntity): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/LegalEntity/${legalEntity.legalEntityId}`;
    return this.deleteAndObserve(targetURL);
  }
}
