import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';
import { environment } from '../../environments/environment';
import { Adal6Service } from 'adal-angular6';
import { Observable } from 'rxjs/Observable';
import { ComponentCommunication } from '../shared/component-communication.service';
import { AuthState, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    //logoURL = '/assets/images/pulse-logo.png';
    //public user: string;
    //public userId: string;
    public userDisplayName: string;
    private sub: any;
    public showHeader: boolean;
    public disableMenu: boolean = false;
    claims: UserClaims[] = [];
    public isAuthenticated: boolean;
    public user;
    public isAuthenticated$!: Observable<boolean>;


    constructor(private authService: AuthService,
        private adalService: Adal6Service, public CompCommunication: ComponentCommunication,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        public oktaAuthState: OktaAuthStateService,
        private router: Router) {
        this.userDisplayName = null;
        this.disableMenu = false;
    }
    ngOnInit() {
        this.disableMenu = false;
        this.CompCommunication.currentMessage.subscribe(msg => {
            this.showHeader = msg['hide-header'];
        });

        this.CompCommunication.disableMenuMessage.subscribe(msg => {
            this.disableMenu = msg['disable-menu'];
        });

        console.log("Loading header!");

        if (environment.IsOktaAuthentication === "true") {
            this.authService.init(this.adalService, this.oktaAuth);
            this.authService.loginSubject.subscribe(x => {
                if (x) {
                    this.isAuthenticated$ = this.oktaAuthState.authState$.pipe(
                        filter((s: AuthState) => !!s),
                        map((s: AuthState) => s.isAuthenticated)
                    );

                    this.isAuthenticated$.subscribe(isAuthenticated => {
                        this.isAuthenticated = isAuthenticated;
                    });
                    console.log("authenticated value - " + this.isAuthenticated);
                    let currentUser = JSON.parse(localStorage.getItem("CurrentUser"));

                    if (currentUser != undefined && currentUser != null) {
                        this.userDisplayName = currentUser.displayName;
                    }

                    if (this.isAuthenticated) {
                        let currentUser = JSON.parse(localStorage.getItem("CurrentUser"));

                        if (currentUser != undefined && currentUser != null) {
                            console.log("okta authenticate value - " + this.isAuthenticated)
                            const userClaims = this.oktaAuth.getUser().then((data) => {

                                console.log("user Name" + data.name + " Preferred Name- " + data.preferred_username)
                                this.userDisplayName = data.name;
                                const oktaToken = this.oktaAuth.getAccessToken();
                                localStorage.setItem('okta.idtoken', oktaToken);
                            });
                        }
                        else {
                            this.oktaAuth.signInWithRedirect({ originalUri: '/' });
                        }
                    }
                }
            });
        }
        else {
            this.login();
            let currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            
            if (currentUser != undefined && currentUser != null) {
                //   console.log("currentUser: " + currentUser.userLoginId);
                //this.userId = currentUser.userLoginId;
                this.userDisplayName = currentUser.displayName;
                this.adalService.getUser().subscribe(x => {
                    console.log("Header LoggedinUser: ")
                    //this.userId = this.adalService.userInfo.username;
                    this.userDisplayName = currentUser.displayName;
                });
            }
        }
    }

    public login() {
        // Handle callback if this is a redirect from Azure
        this.adalService.handleWindowCallback()
        console.log("Outside if(!this.adalService.userInfo.authenticated)");
        console.log(this.adalService.userInfo);
        // Check if the user is authenticated. If not, call the login() method
        if (!this.adalService.userInfo.authenticated) {
            console.log("Inside if(!this.adalService.userInfo.authenticated)");
            this.adalService.login();
        }

        console.log(this.adalService.userInfo.profile, " -  this.adalService.userInfo.profile");
        if (this.adalService.userInfo.profile !== undefined && this.adalService.userInfo.profile !== null) {
            this.userDisplayName = this.adalService.userInfo.profile.name;
        }

        this.authService.init(this.adalService, this.oktaAuth);
    }

    logout(): void {
        localStorage.setItem("IsLogoutFromSystem", JSON.stringify(true));
        if (environment.IsOktaAuthentication === "true") {
            this.oktaAuth.signOut();
        }
        else {
            this.adalService.logOut();
        }
    };
}
