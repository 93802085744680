import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    // logoURL = '/assets/images/pulse-logo.png';
    appVersion: string = environment.appVersion;
    constructor() {
    }
}
