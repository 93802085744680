import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserService } from './user.service'
import { BaseService } from "./baseService";
import { Router } from '@angular/router';
import { LoggingService } from './logging.service';
import * as _ from 'lodash';

@Injectable()
export class UserPreferenceService extends BaseService {

  private preferences: Array<any> = [];

  constructor(private http: HttpClient,
    private userService: UserService,
    private router: Router, private paramLoggingSvc: LoggingService) {
    super(null, paramLoggingSvc);
    this.init();
  }

  private init() {
    this.preferences = JSON.parse(localStorage.getItem("CurrentUserPreferences"));
  }

  get DefaultDateFormate(): string {
    var finalDateFormat = '';
    var _defaultDateFormate = 'dd/MM/yyyy';
    if (this.preferences) {
      var defaultDateFormat = _.find(this.preferences,
        function (p) { return p.key == 'Default Date Format' });
      if (defaultDateFormat) {
        var dateFormateValue = (<any>defaultDateFormat).value;
        if (dateFormateValue) {
          finalDateFormat = dateFormateValue.
            toLowerCase().replace('mm', 'MM');
        }
      }
    }
    return finalDateFormat || _defaultDateFormate;
  }
}

