import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';

import {
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatTabsModule,
  MatButtonModule,
  MatCheckboxModule,
  MatGridListModule,
  MatInputModule,
  MatCardModule, MatDialogModule, MatTableModule,
  MatMenuModule, MatProgressSpinnerModule,
  MatRadioModule, MatSelectModule,
  MatNativeDateModule, MatExpansionModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTreeModule,
  MatDialogRef
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatGridListModule,
    MatInputModule,
    MatChipsModule,
    MatCardModule, MatDialogModule, MatTableModule,
    MatMenuModule, MatProgressSpinnerModule,
    MatRadioModule, MatSelectModule, MatDatepickerModule,
    MatNativeDateModule, MatExpansionModule,
    MatProgressBarModule, MatPaginatorModule, MatAutocompleteModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTooltipModule,
    MatTreeModule
  ],
  exports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatInputModule,
    MatCardModule, MatDialogModule, MatTableModule,
    MatMenuModule, MatProgressSpinnerModule,
    MatRadioModule, MatSelectModule, MatDatepickerModule,
    MatNativeDateModule, MatExpansionModule,
    MatProgressBarModule, MatPaginatorModule, MatAutocompleteModule,
    MatSlideToggleModule,
    MatSortModule, MatTreeModule, MatTooltipModule
  ]
})
export class AppMaterialModule { }