import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LegalEntity, LegalEntityUser, LegalEntityCleint } from 'src/app/models/LegalEntity.model';
import { LegalEntityService } from 'src/app/http-services/legal-entity.service';
import { SearchCriteria } from 'src/app/models/SearchCriteria.model';
import * as _ from 'lodash';
import { ResultSet } from 'src/app/models/ResultSet.model';
import { Client } from 'src/app/models/Client.model';
import { User } from 'src/app/models/User.model';
import { UserDetailsService } from 'src/app/http-services/user-details.service';
import { SPsNUsersDetails } from 'src/app/models/SPsNUsersDetails.model';
import { AuthzUserInfo } from 'src/app/models/AuthzUserInfo.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReceivingEmailsValidator } from 'src/app/common/validators/receivingEmails.validator';
import { CDMUserValidator } from 'src/app/common/validators/cdmUser.validator';
import { CDMClientValidator } from 'src/app/common/validators/cdmClient.validator';
import { ConfirmationDialogData } from 'src/app/common/common.confirmation.dialog.component';
import { AuthService } from 'src/app/shared/auth.service';


@Component({
  selector: 'new-edit-legal-entity',
  styleUrls: ['./new-edit-legal-entity.component.scss'],
  templateUrl: 'new-edit-legal-entity.component.html',
})
export class NewEditLegalEntityComponent implements OnInit {
  legalEntity: LegalEntity;
  newOrEditLegalEntityFormGroup: FormGroup;

  clients: Array<Client>;
  users: Array<User>;
  currentLoggedInUser: User;
  client: Client;
  currentUser: AuthzUserInfo;
  user: User;
  superUserId: string;
  spsNUsersDetails: SPsNUsersDetails;
  isAdminstrator: boolean;
  receivingEmails: Array<string> = new Array<string>();
  currentEmail: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewEditLegalEntityComponent>,
    private legalEntityService: LegalEntityService,
    private userDetailsSvc: UserDetailsService,
    public authService: AuthService,
    private router: Router,
    private toastr: ToastrService) {
    this.router.events.subscribe((val) => {
      if (!this.dialog) return;
      this.dialog.closeAll();
    });
    this.init();
  }

  private init() {
    this.superUserId = this.userDetailsSvc.CurrentUserLoginId;
    this.currentUser = this.userDetailsSvc.CurrentUser;
    var searchCriteria = <SearchCriteria>{ pageSize: 100, pageIndex: 0 };
    this.clients = new Array<Client>();
    this.users = new Array<User>();
    this.client = new Client();
    this.user = new User();
    this.legalEntity = this.data.dialogData.legalEntity || <LegalEntity>{};
    this.loadClients(searchCriteria);
    this.loadUsers(searchCriteria);



    this.legalEntityDetailObserver();

  }

  private legalEntityDetailObserver() {
    this.newOrEditLegalEntityFormGroup = new FormGroup({
      legalEntityCode: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100)
      ]),
      legalEntityName: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100)
      ]),
      receivingEmails: new FormControl("",
        [
          // Validators.required,
          // Validators.minLength(1),
          // Validators.maxLength(300),
          // ReceivingEmailsValidator.validate
        ]),
      currentEmail: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(150),
        ReceivingEmailsValidator.validate(this.receivingEmails)
      ]),
      users: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
        CDMUserValidator.validate(this.users)
      ]),
      clients: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
        CDMClientValidator.validate(this.clients)
      ])
    });

    this.filteredUsersOptions = this.newOrEditLegalEntityFormGroup.controls.users.valueChanges
      .pipe(
        startWith<string | User>(''),
        map(value => typeof value === 'string' ? value : value.firstName),
        map(name => name ? this._filterUser(name) : this.users.slice())
      );

    this.filteredClientsOptions = this.newOrEditLegalEntityFormGroup.controls.clients.valueChanges
      .pipe(
        startWith<string | Client>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterClient(name) : this.clients.slice())
      );


    this.data.dialogData.legalEntity = this.data.dialogData.legalEntity || <LegalEntity>{};
    let _legalEntity = this.data.dialogData.legalEntity as LegalEntity;
    if (_legalEntity.receivingEmails) {
      this.receivingEmails = _.split(_legalEntity.receivingEmails, ',');
      if (this.receivingEmails.length > 0) {
        if (!this.data.dialogData.isNew) {
          this.currentEmail = this.receivingEmails[0];
          this.receivingEmails.splice(0, 1);
        }
      }
    }
    if (_legalEntity.users) {
      if (_legalEntity.users.length > 0) {
        var _user = (<any>_legalEntity.users[0]).cdmUserInfo as User;
        this.user = _user;
        this.setDefaultUser(_user);
      }
    }

    let _client = this.data.dialogData.client as Client;
    if (_client) {
      this.client = _client;
      this.setDefaultClient(_client);
    } else {
      if (_legalEntity.clients) {
        if (_legalEntity.clients.length > 0) {
          _client = (<any>_legalEntity.clients[0]).cdmClient as Client;
          this.client = _client;
          this.setDefaultClient(_client);
        }
      } else {
        _client = this.clients[0] || <Client>{};
        this.setDefaultClient(this.clients[0]);
      }
    }
  }

  public addReceivingEmails(email: string) {
    if (!email)
      return;
    this.receivingEmails = this.receivingEmails || new Array<string>();
    if (this.isValidEmail(email)) {
      if (!this.isDuplicateReceivingEmails(email)) {
        this.receivingEmails.push(email);
        this.currentEmail = "";
        this.legalEntity.receivingEmails = this.genrateReceivingEmails(this.receivingEmails);
      } else {
        this.toastr.error(`Duplicate receiving emails.`, `"${email}" is duplicate receiving emails.`);
      }
    } else {
      this.toastr.error(`Invalid email id.`, `"${email}" is invalid email id.`);
    }
  }
  private genrateReceivingEmails(receivingEmails: Array<string>) {
    if (receivingEmails.length > 0)
      return receivingEmails.join(',')
    else
      return "";
  }
  public removeReceivingEmails(email: string) {
    this.receivingEmails = this.receivingEmails || new Array<string>();
    if (email) {
      var index = this.receivingEmails.indexOf(email);
      if (index !== -1) {
        //delete this.receivingEmails[index];
        this.receivingEmails.splice(index, 1);
        this.legalEntity.receivingEmails = this.genrateReceivingEmails(this.receivingEmails);
      }
    }

  }
  private isDuplicateReceivingEmails(email: string) {
    this.receivingEmails = this.receivingEmails || new Array<string>();
    return this.receivingEmails.indexOf(email) !== -1;
  }
  public isValidEmail(email: string) {
    if (!email)
      return false;
    var validEmails = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const match = this.currentEmail.match(validEmails);
    return match != null;
  }
  private loadClients(searchCriteria: SearchCriteria) {
    //this.spinnerService.turnOnModal();
    this.legalEntityService.getClients(searchCriteria).subscribe((data: any) => {
      var resultSet = data.body as ResultSet;
      this.clients = resultSet.results as unknown as Client[];
      //this.spinnerService.turnOffModal();
      if (this.data.dialogData.isNew) {
        let _client = this.client || this.clients[0];
        this.setDefaultClient(_client);
      }
    });
  }
  private loadUsers(searchCriteria: SearchCriteria) {
    //this.spinnerService.turnOnModal();
    this.legalEntityService.getUsers(searchCriteria).subscribe((data: any) => {
      var resultSet = data.body as ResultSet;
      this.users = resultSet.results as unknown as User[];
      //this.spinnerService.turnOffModal();
      if (this.data.dialogData.isNew) {
        let currutUserOptin = _.find(this.users, { userNumberId: this.currentUser.userNumberId });
        this.currentLoggedInUser = currutUserOptin;
        this.setDefaultUser(currutUserOptin);
      }
    });
  }


  filteredUsersOptions: Observable<User[]>;
  filteredClientsOptions: Observable<Client[]>;

  private setOkCancelStatus(isOkClicked: boolean) {
    this.data.isOkClick = isOkClicked;
    this.data.isCancelClick = !isOkClicked;
  }

  onNoClick(): void {
    this.setOkCancelStatus(false);
    this.dialogRef.close(this.data);
  }

  onOkClick(): void {
    this.setOkCancelStatus(true);
    //this.dialogRef.close(this.data);
  }

  ngOnInit() {


  }

  displayUserFn(user?: User): string | undefined {
    return user ? `${user.lastName} , ${user.firstName} ( ${user.emailAddress} )` : undefined;
  }
  displayClientFn(client?: Client): string | undefined {
    return client ? client.name : undefined;
  }

  private _filterUser(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.users.filter(option => option.firstName.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterClient(name: string): Client[] {
    const filterValue = name.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }


  setUser(event: any) {
    if (event.option.selected) {
      this.user = event.option.value as User;
    }
  }
  setClient(event: any) {
    if (event.option.selected) {
      this.client = event.option.value as Client;
    }
  }
  private setDefaultUser(user: User) {
    this.user = user;
    this.newOrEditLegalEntityFormGroup.controls.users.setValue(user);
  }
  private setDefaultClient(client: Client) {
    this.client = client;
    this.newOrEditLegalEntityFormGroup.controls.clients.setValue(client);
  }

  private emailCleanUp() {
    if (this.isValidEmail(this.currentEmail)) {
      if (this.receivingEmails.indexOf(this.currentEmail) == -1) {
        this.receivingEmails.push(this.currentEmail);
        this.legalEntity.receivingEmails = this.genrateReceivingEmails(this.receivingEmails);
      }
    }
  }

  onSubmit(): void {
    if (!this.newOrEditLegalEntityFormGroup.valid)
      return;

    if (!this.legalEntity)
      return;

    this.emailCleanUp();

    this.legalEntity.users = null;
    if (this.user) {
      this.legalEntity.users = new Array<LegalEntityUser>();
      var _legalEntityUser = new LegalEntityUser();
      _legalEntityUser.legalEntityId = this.legalEntity.legalEntityId;
      _legalEntityUser.userNumberId = this.user.userNumberId;
      this.legalEntity.users.push(_legalEntityUser);
    }

    this.legalEntity.clients = null;
    if (this.client) {
      this.legalEntity.clients = new Array<LegalEntityCleint>();
      var _legalEntityCleint = new LegalEntityCleint();
      _legalEntityCleint.legalEntityId = this.legalEntity.legalEntityId;
      _legalEntityCleint.clientId = this.client.clientId;
      this.legalEntity.clients.push(_legalEntityCleint);
    }
    this.onOkClick();
    var description = `Code  ${this.legalEntity.legalEntityCode} ? <br/> Name ${this.legalEntity.legalEntityName}  <br/> Emails ${this.legalEntity.receivingEmails}`;
    if (this.data.dialogData.isNew) {
      this.legalEntity.legalEntityId = 0;
      this.legalEntityService.addLegalEntity(this.legalEntity).subscribe((data) => {

        if (data.ok && data.status == 200) {
          if (data.body.cdmStatusCode == 200) {
            //created
            this.dialogRef.close(this.data);
            this.toastr.success(`Legal entity ${this.legalEntity.legalEntityName} has been saved successfully.`,
              `${this.legalEntity.legalEntityName}`,
              { enableHtml: true });
          } else if (data.body.cdmStatusCode == 500) {
            //not created
            var errorMessagee = _.join(_.map(data.body.cdmFaultsBox.faultsList, 'faultText'), '</br>');
            this.toastr.error(errorMessagee,
              `Legal entity ${this.legalEntity.legalEntityName} has not been saved successfully.`,
              { enableHtml: true });
          }
        } else {
          //not created
          this.toastr.error(description, `Legal entity ${this.legalEntity.legalEntityName} has not been saved successfully.`,
            { enableHtml: true });
        }
      });
    }
    else {
      this.legalEntityService.editLegalEntity(this.legalEntity).subscribe((data) => {

        if (data.ok && data.status == 200) {
          if (data.body.cdmStatusCode == 200) {
            //updated
            this.dialogRef.close(this.data);
            this.toastr.success(`Legal entity ${this.legalEntity.legalEntityName} has been updated successfully.`,
              `${this.legalEntity.legalEntityName}`, { enableHtml: true });
          } else if (data.body.cdmStatusCode == 500) {
            //not updated
            var errorMessagee = _.join(_.map(data.body.cdmFaultsBox.faultsList, 'faultText'), '</br>');
            this.toastr.error(errorMessagee, `Legal entity ${this.legalEntity.legalEntityName} has not been saved successfully.`,
              { enableHtml: true });
          }
        } else {
          //not updated
          this.toastr.error(description, `Legal entity ${this.legalEntity.legalEntityName} has not been saved successfully.`,
            { enableHtml: true });
        }
      });
    }
  }
}