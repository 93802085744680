import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
//import { MsalService } from '../shared/authentication/services/msal.service';
//import { AuthenticationService } from 'app/shared/authentication/services/authentication.service';
import { User } from 'msal';
import { Adal6Service } from 'adal-angular6';
//import { LookupService } from 'app/shared/lookup.service';
import { setTimeout, setInterval } from 'timers';
//import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { ComponentCommunication } from '../shared/component-communication.service';
import { WSO2Service } from '../shared/wso2.service';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public user: User = null;
  public userInfo: any = null;
  public apiCallFailed: boolean;
  public loginFailed: boolean;
  public token: string;
  public authorizationStatusMessage: string;
  public error: Error | null = null;
  public isOktaAuthenticated: boolean = false;

  constructor(private authService: AuthService,
    private router: Router,
    private adalService: Adal6Service,
    public CompCommunication: ComponentCommunication,
    private wso2Service: WSO2Service,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public oktaAuthState: OktaAuthStateService) {
    console.log("Inside Home constructor");

  }

  async ngOnInit() {
    if (environment.IsOktaAuthentication === "true") {
      await this.Oktalogin();
    }
    else {
      this.login();
    }
  }

  Oktalogin() {
    localStorage.removeItem("IsUserAllowed");
    this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': false });

    if (!this.oktaAuth.authStateManager._authState.isAuthenticated) {
      this.oktaAuth.signInWithRedirect({ originalUri: '/' });
    }
    else {
      const oktaToken = this.oktaAuth.getAccessToken();
      localStorage.setItem('okta.idtoken', oktaToken);
    }
    
    this.authService.init(this.adalService, this.oktaAuth);
    this.authService.loginSubject.subscribe(x => {
      if (x) {
        this.router.navigate(['my-calendar']);
        localStorage.setItem("IsUserAllowed", JSON.stringify(true));
        this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': false });
      }
      else {
        this.authorizationStatusMessage = this.authService.authorizationStatusMessage;
        localStorage.setItem("IsUserAllowed", JSON.stringify(false));
        this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': true });
      }
    });
  }

  public login() {

    localStorage.removeItem("IsUserAllowed");
    this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': false });
    // Handle callback if this is a redirect from Azure
    this.adalService.handleWindowCallback();

    if (!this.adalService.userInfo.authenticated) {
      this.adalService.login();
      this.wso2Service.getWSO2RefreshToken();
    }
    this.authService.init(this.adalService, this.oktaAuth);
    this.authService.loginSubject.subscribe(x => {
      if (x) {
        this.router.navigate(['my-calendar']);
        localStorage.setItem("IsUserAllowed", JSON.stringify(true));
        this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': false });
      }
      else {
        this.authorizationStatusMessage = this.authService.authorizationStatusMessage;
        localStorage.setItem("IsUserAllowed", JSON.stringify(false));
        this.CompCommunication.changeDisableMenuMessage({ 'disable-menu': true });
      }
    });
  }
}
