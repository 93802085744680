import { FormControl, Validators } from '@angular/forms';
// setup simple regex for white listed characters
const validEmails = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// create your class that extends the angular validator class
export class ReceivingEmailsValidator extends Validators {
    static validate = (receivingEmails: Array<string>) => {
        return (control: FormControl) => {
            let error = null;
            let isReceivingEmailsValid = false;
            if (receivingEmails.length > 0) {
                let matches = [];
                for (var i = 0; i < receivingEmails.length; i++) {
                    // if there are matches return an object, else return null.
                    let emailString = receivingEmails[i].trim();
                    const _matches = emailString.match(validEmails);
                    matches.push(!!_matches);
                }
                if (matches.indexOf(false) !== -1) {
                    matches = null;
                    isReceivingEmailsValid = false;
                    error = { invalid_receiving_emails: matches };
                }
                else {
                    isReceivingEmailsValid = true;
                    //return null;
                }
            } 
            // else {
            //     isReceivingEmailsValid = true;
            // }
            let isEmailValid = false;
            if (control.value && control.value.length > 0) {
                // match the control value against the regular expression
                let matches = [];
                const _matches = control.value.match(validEmails);
                matches.push(!!_matches);
                if (matches.indexOf(false) !== -1) {
                    matches = null;
                    isEmailValid = false;
                    error = { invalid_receiving_emails: matches };
                }
                else {
                    isEmailValid = true;
                    // return null;
                }
            } 
            // else {
            //     isEmailValid = true;
            // }

            if (isReceivingEmailsValid && isEmailValid) {
                return null;
            }
            else {
                return error;
            }
        }
    }
}
