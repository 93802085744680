import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../../shared/spinner.service';
import { LoanDetailsService } from '../../http-services/loan-details.service';
import { RegionService } from '../../http-services/region.service';
import { LoggingService } from '../../shared/logging.service';
import * as _ from 'lodash';
import { LoanDetailsRequestBulkInsert } from '../../models/LoanDetailsRequestBulkInsert.model';
import { Region } from '../../models/Region.model';

@Component({
  selector: 'app-loan-process',
  templateUrl: './loan-process.component.html',
  styleUrls: ['./loan-process.component.scss']
})
export class LoanProcessComponent implements OnInit {
  loanProcessFormGroup: FormGroup;
  filePath: string = "";
  loanDetailsRequestBulkInsert: LoanDetailsRequestBulkInsert = new LoanDetailsRequestBulkInsert();
  regions: Array<Region> = new Array<Region>();

  constructor(private spinnerService: SpinnerService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private loggingService: LoggingService,
    private loanDetailsService: LoanDetailsService,
    private regionService: RegionService) {
    this.spinnerService.show();
    this.loadRegions();
  }

  ngOnInit() {
    this.loanProcessFormGroup = new FormGroup({
      fileName: new FormControl("", [
        Validators.required
      ]),
      regionId: new FormControl("", [
        Validators.required,
        Validators.min(1)
      ])
    });
  }

  loadRegions() {
    this.regionService.getActiveRegions().subscribe((data: any) => {
      this.regions = data.body as Array<Region>;
      this.spinnerService.hide();
    }, (error) => {
      this.loggingService.logError(error);
      this.spinnerService.hide();
    });
  }

  clear() {
    this.filePath = "";
    this.loanDetailsRequestBulkInsert.fileData = new Array<any>();
    this.loanDetailsRequestBulkInsert.regionId = null;
  }

  readFile(fileEvent: any) {
    this.loanDetailsRequestBulkInsert.fileData = new Array<any>();
    const file = fileEvent.target.files[0];
    let ext = file.name.substring(file.name.lastIndexOf("."));

    if (file == null || file == undefined) {
      this.toastr.info("Please select a file to process.");
      return;
    }

    if (['.csv'].indexOf(ext) == -1) {
      this.toastr.info("Only csv files are allowed. ");
      this.clear();
      return;
    }

    if (file.size > 4194304) {
      this.toastr.info("File size greater then 4MB not allowed.", "File Size");
      this.clear();
      return;
    }

    var reader = new FileReader();
    let doc = this.loanDetailsRequestBulkInsert.fileData;
    reader.onload = function (e: any) {
      var theBytes = e.target.result;
      let array = new Uint8Array(theBytes);
      for (var i = 0; i < array.length; i++) {
        doc.push(array[i]);
      }
    };

    reader.readAsArrayBuffer(file);
  }

  onProcessLoanFile() {
    this.spinnerService.show();
    this.loanDetailsService.processLoanDetailsFile(this.loanDetailsRequestBulkInsert.fileData).subscribe((data) => {
      this.resultSubmit(data);
    }, (error) => {
      this.loggingService.logError(error);
      const title = `Processing Loan File`;
      this.toastr.error('Error occured in processing Loan file. <br/>' + error.errorMessagee, title, { enableHtml: true });
      this.spinnerService.hide();
    });
  }

  onProcessLoanValuationFile() {
    this.spinnerService.show();
    this.loanDetailsService.processLoanValuationFile(this.loanDetailsRequestBulkInsert.fileData).subscribe((data) => {
      this.resultValuationSubmit(data);
    }, (error) => {
      this.loggingService.logError(error);
      const title = `Processing Loan Valuation File`;
      this.toastr.error('Error occured in processing Loan Valuation file. <br/>' + error.errorMessagee, title, { enableHtml: true });
      this.spinnerService.hide();
    });
  }

  private resultSubmit(data) {
    const title = `Processing Loan File`;
    if (data.ok && data.status === 200 && data.body.cdmStatusCode === 200) {
      this.toastr.success('Processing of Loan File is completed.', title, { enableHtml: true });
    } else {
      // Error in Saving Loan Details.
      const errorMessagee = _.join(_.map(data.body.cdmFaultsBox.faultsList, 'faultText'), '</br>');
      this.toastr.error('Error occured in processing Loan file. <br/>' + errorMessagee, title, { enableHtml: true });
    }

    this.spinnerService.hide();
  }

  private resultValuationSubmit(data) {
    const title = `Processing Loan Valuation File`;
    if (data.ok && data.status === 200 && data.body.cdmStatusCode === 200) {
      this.toastr.success('Processing of Loan Valuation file is completed.', title, { enableHtml: true });
    } else {
      // Error in Saving Loan Details.
      const errorMessagee = _.join(_.map(data.body.cdmFaultsBox.faultsList, 'faultText'), '</br>');
      this.toastr.error('Error occured in processing Loan Valuation file. <br/>' + errorMessagee, title, { enableHtml: true });
    }

    this.spinnerService.hide();
  }

  onProcessBulkInsertLoanFile() {
    this.spinnerService.show();
    this.loanDetailsService.onProcessBulkInsertLoanFile(this.loanDetailsRequestBulkInsert).subscribe((data) => {
      this.resultSubmit(data);
    }, (error) => {
      this.loggingService.logError(error);
      const title = `Processing Loan File`;
      this.toastr.error('Error occured in processing Loan file. <br/>' + error.errorMessagee, title, { enableHtml: true });
      this.spinnerService.hide();
    });
  }
}
