import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})
export class IframeDetectionService {
 
  constructor() { }
 
  // Method to detect if the page is loaded in an iframe
  isInIframe(): boolean {
    return window.top !== window.self; // If the page is in an iframe, window.top is not equal to window.self
  }
 
  // Method to handle blocking the page if in an iframe
  handleIframe() {
    if (this.isInIframe()) {
      // Show a warning message
      alert('Unable to load this page in an iframe. Please visit the page directly.');
 
      // Disable the page's content or prevent user interaction
      document.body.innerHTML = ''; // Clear the page content to block further interaction
 
      // Optionally, you can log out the user or force a redirection:
      // this.router.navigate(['/login']);  // Uncomment if you want a redirect to login.
    }
  }
}