import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { SearchCriteria, MyTasksSearchCriteria } from '../models/SearchCriteria.model';
import { ResultSet } from '../models/ResultSet.model';
import { RecordStatus } from '../models/RecordStatus.model';
import { BaseService } from '../shared/baseService';
import { Covenant } from '../models/Covenant.model';
import { forkJoin } from 'rxjs';
import { LoggingService } from '../shared/logging.service';
import { CovenantDocumentList } from '../models/DocumentList.model';
import { EmailScheduleQueue } from '../models/EmailScheduleQueue.model';
import { CovenantEmailSchedule } from '../models/CovenantEmailSchedule.model';

@Injectable({
  providedIn: 'root'
})
export class CovenantService extends BaseService {

  constructor(private paramHttpClient: HttpClient, private paramLoggingSvc: LoggingService) {
    super(paramHttpClient, paramLoggingSvc);
  }

  // getCovenants(searchCriteria: SearchCriteria): Observable<ResultSet> {
  //   var targetURL = `${environment.apiUrl}/api/Covenant/Covenants`;
  //   var _params = new HttpParams().
  //     set('id', searchCriteria.id.toString()).
  //     set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
  //     set('pageSize', searchCriteria.pageSize.toString()).
  //     set('pageIndex', searchCriteria.pageIndex.toString()).
  //     set('active', searchCriteria.active).
  //     set('direction', searchCriteria.direction).
  //     set('query', searchCriteria.query);

  //   return this.getReqAndObserve(targetURL, _params);
  // }

  getCovenantsByLoan(searchCriteria: SearchCriteria): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Covenant/Covenants/${searchCriteria.loanID}`;
    const _params = new HttpParams().
    set('id', searchCriteria.id.toString()).
    set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
    set('pageSize', searchCriteria.pageSize.toString()).
    set('pageIndex', searchCriteria.pageIndex.toString()).
    set('active', searchCriteria.active).
    set('direction', searchCriteria.direction);
    return this.getReqAndObserve(targetURL, _params);
  }

  fetchUsers(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UserInformation/UsersNRoles`;
    return this.getAndObserve(targetURL);
  }

  fetchCovenantTypes(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantType`;
    return this.getAndObserve(targetURL);
  }

  fetchTypeFrequency(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantInfo`;
    return this.getAndObserve(targetURL);
  }
  fetchTypeFrequencyUsers(covenantId: number = 0): Observable<any[]> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantInfo`;
    const targetURL1 = `${environment.apiUrl}/api/Covenant/UsersAssignedTo/${covenantId}`;
    const covenantDetails = this.getAndObserve(targetURL);
    const userList = this.getAndObserve(targetURL1);
    return forkJoin([covenantDetails, userList]);
  }

  addCovenant(covenant: Covenant): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant`;
    return this.postAndObserve(targetURL, covenant);
  }
  updateCovenantComments(covenant: Covenant): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/UpdateCovenantComments`;
    return this.putAndObserve(targetURL, covenant);
  }

  CovenantArchiveRequest(data: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantArchiveRequest`;
    return this.postAndObserve(targetURL, data);
  }
  saveEditedTask(data: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/EditCovenantTask`;
    return this.putAndObserve(targetURL, data);
  }
  saveTaskCompletion(data: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantTaskComplete`;
    return this.postAndObserve(targetURL, data);
  }
  saveTaskReview(data: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenanteTaskReview`;
    return this.postAndObserve(targetURL, data);
  }
  CovenantArchive(data: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/ArchiveCovenant`;
    return this.postAndObserve(targetURL, data);
  }
  dateFormat(userId: number): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Preference/GetPreference/${userId}`;
    return this.getAndObserve(targetURL);
  }

  approveCovenant(covenantId: number): Observable<ResultSet> {
    const targetURL = `${environment.apiUrl}/api/Covenant/ApproveCovenant/${covenantId}`;
    return this.getAndObserve(targetURL);
  }

  getCovenant(covenantId: number, searchCriteria: SearchCriteria): Observable<any[]> {
    const targetURL = `${environment.apiUrl}/api/Covenant/GetCovenantById/${covenantId}`;
    const targetURL1 = `${environment.apiUrl}/api/Covenant/CovenantTasksByCovenant/${covenantId}`;
    const targetURL2 = `${environment.apiUrl}/api/Covenant/CovenantTaskHistory/${covenantId}`;
    const covenantDetails = this.getAndObserve(targetURL);
    const taskList = this.getAndObserve(targetURL1);
    const historyList = this.getCovenantsHistoryList(targetURL2, searchCriteria);
    return forkJoin([covenantDetails, taskList, historyList]);
  }

  getHistory(covenantId: number, searchCriteria: SearchCriteria): Observable<any[]> {
    const targetURL2 = `${environment.apiUrl}/api/Covenant/CovenantTaskHistory/${covenantId}`;
    const _params = new HttpParams().
    set('id', searchCriteria.id.toString()).
    set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
    set('pageSize', searchCriteria.pageSize.toString()).
    set('pageIndex', searchCriteria.pageIndex.toString()).
    set('active', searchCriteria.active).
    set('direction', searchCriteria.direction);
    return this.getReqAndObserve(targetURL2, _params);
  }

  getCovenantsHistoryList(url, searchCriteria: SearchCriteria): Observable<ResultSet> {
    const targetURL = url;
    const _params = new HttpParams().
    set('id', searchCriteria.id.toString()).
    set('conditionOnTable', searchCriteria.conditionOnTable.toString()).
    set('pageSize', searchCriteria.pageSize.toString()).
    set('pageIndex', searchCriteria.pageIndex.toString()).
    set('active', searchCriteria.active).
    set('direction', searchCriteria.direction);
    return this.getReqAndObserve(targetURL, _params);
  }

  getCovenantTasks(startDate: string, endDate: string): Observable<any> {
    startDate = startDate.replace(/,/g, '%2c');
    endDate = endDate.replace(/,/g, '%2c');
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantTasks/${startDate}/${endDate}`;
    return this.getAndObserve(targetURL);
  }

  /*getAllCovenantTasks(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantTasks`;
    return this.getAndObserve(targetURL);
  }*/

  getAllCovenantTasks(searchCriteria: MyTasksSearchCriteria): Observable<any> {
    var targetURL = `${environment.apiUrl}/api/Covenant/CovenantTasks`;
    var _params = new HttpParams().
      set('clientId', searchCriteria.clientId.toString()).
      set('covenantTypeId', searchCriteria.covenantTypeId.toString()).
      set('pageSize', searchCriteria.pageSize.toString()).
      set('pageIndex', searchCriteria.pageIndex.toString()).
      set('active', searchCriteria.active).
      set('direction', searchCriteria.direction).
      set('query', searchCriteria.query);

    return this.getReqAndObserve(targetURL, _params);
  }
  

  getTaskCompletionDetails(taskId: Number): Observable<any[]> {
    const targetURL = `${environment.apiUrl}/api/Covenant/GetTaskCompletionByTaskId/${taskId}`;
    const targetURL1 = `${environment.apiUrl}/api/Covenant/GetTaskDocuments/${taskId}`;
    const completionDetails = this.getAndObserve(targetURL);
    const documentList = this.getAndObserve(targetURL1);
    return forkJoin([completionDetails, documentList]);
  }

  editCovenant(covenant: Covenant): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/${covenant.covenantId}`;

     return this.putAndObserve(targetURL, covenant);
  }
  deleteCovenant(covenantId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/DeleteCovenant/${covenantId}`;
    return this.deleteAndObserve(targetURL);
  }

  uploadDocument(document: CovenantDocumentList): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/UploadTaskDocument`;
    return this.postAndObserve(targetURL, document);
  }

  downloadDocument(DocumentId: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/DownloadTaskDocument/${DocumentId}`;
    const modifiedURL = targetURL.replace(/,/g, '%2c');
    return this.download(modifiedURL);
   // window.location.href = targetURL;
  }

  getDocumentList(taskId: Number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/GetTaskDocuments/${taskId}`;
    return this.getAndObserve(targetURL);
  }

  deleteDocument(documentId: any): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/DeleteTaskDocument/${documentId}`;
    const modifiedURL = targetURL.replace(/,/g, '%2c');
    return this.deleteAndObserve(modifiedURL);
  }

  getTaskDetailsForReminder(taskId: number): Observable<any>{
    const targetURL = `${environment.apiUrl}/api/Covenant/GetTaskDetailsForReminder/${taskId}`;
    return this.getAndObserve(targetURL);
  }

  getAlreadyExistReminder(covenantId: number): Observable<any>{
    const targetURL = `${environment.apiUrl}/api/Covenant/GetCovenantEmailSchedule/${covenantId}`;
    return this.getAndObserve(targetURL);
  }

  saveTaskReminderEmail(covenantEmailSchedule: CovenantEmailSchedule): Observable<any>{
    const targetURL = `${environment.apiUrl}/api/Covenant/SaveTaskReminderEmail`;
    return this.postAndObserve(targetURL, covenantEmailSchedule);
  }

  updateTaskReminderEmail(covenantEmailSchedule: CovenantEmailSchedule): Observable<any>{
    const targetURL = `${environment.apiUrl}/api/Covenant/UpdateTaskReminderEmail`;
    return this.putAndObserve(targetURL, covenantEmailSchedule);
  }

  saveReminderInEmailQueue(emailScheduleQueue: EmailScheduleQueue): Observable<any>{
    const targetURL = `${environment.apiUrl}/api/Covenant/UpdateEmailScheduleQueue`;
    return this.putAndObserve(targetURL, emailScheduleQueue);
  }

  deleteTaskReminder(taskId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/DeleteTaskReminderEmail/${taskId}`;
    return this.deleteAndObserve(targetURL);
  }

  covenantSendBack(taskId: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Covenant/CovenantSendBack/${taskId}`;
    return this.getAndObserve(targetURL);
  }
}
