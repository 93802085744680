import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable'
import { Subject } from "rxjs/Subject";
import { environment } from '../../environments/environment';
import { UserService } from './user.service'
import { BaseService } from "./baseService";
import { Router } from '@angular/router';
import { Adal6Service } from 'adal-angular6';
//import { LookupService } from './lookup.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AuthzUserInfo } from '../models/AuthzUserInfo.model';
import { LoggingService } from '../shared/logging.service';
import { WSO2Service } from '../shared/wso2.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService } from '@okta/okta-angular';
import { catchError } from 'rxjs/operators';



@Injectable()
export class AuthService extends BaseService {

  private loginId: string;
  public isAdmin = false;
  public isMasterUser = false;
  public isCCDirector = false;
  ccdirectorRole = "CC Director";
  adminRole = "Administrator";
  public loginSubject = new BehaviorSubject<boolean>(false);
  public loginNotificaton = new Subject<string>();
  public authorizationStatusMessage: string;
  private oktaUserName: string;

  constructor(private http: HttpClient,
    private userService: UserService,
    //private lookupService: LookupService,
    private router: Router,
    private paramLoggingSvc: LoggingService,
    private wso2Service: WSO2Service) {
    super(null, paramLoggingSvc);
    this.paramLoggingSvc.logDebug("Inside \"AuthService\" constructor");
  }

  getLoginNotification(): Observable<any> {

    return this.loginSubject;

  }

  init(paramAdalService: Adal6Service, paramOktaAuth: OktaAuth) {

    this.paramLoggingSvc.logDebug("Inside \"init(paramAdalService: Adal6Service)\"");

    this.adalService = paramAdalService;
    this.oktaAuth = paramOktaAuth;

    if (environment.IsOktaAuthentication === "true") {
      const isOktaAuthenticated = this.oktaAuth.isAuthenticated().then
        (x => {
          if (x.valueOf() && this.oktaAuth) {
            this.oktaAuth.getUser().then((userData) => {
              this.paramLoggingSvc.logDebug("UserName: " + userData.name);
              this.loginId = userData.email;

              if (userData.name == "") {
                this.paramLoggingSvc.logDebug("User name is empty string!");
              }
              if (environment.apiUrl.indexOf("localhost") === -1) {
                this.checkUserDetailsThroughWSO2();
              }
              else {
                this.checkUserDetails();
              }
            });
          }
          else {
            this.loginSubject.next(true);
          }
        });

    }
    else {
      this.adalService.getUser().subscribe(x => {
        if (this.adalService.userInfo.authenticated) {

          if (this.adalService.userInfo.username == "") {
            this.paramLoggingSvc.logDebug("User name is empty string!");
            this.adalService.userInfo.username = this.adalService.userInfo.profile.unique_name;
          }
          this.paramLoggingSvc.logDebug("UserName: ");
          this.paramLoggingSvc.logDebug(this.adalService.userInfo);

          this.loginId = this.adalService.userInfo.username;

          if (environment.apiUrl.indexOf("localhost") === -1) {
            this.checkUserDetailsThroughWSO2();
          }
          else {
            this.checkUserDetails();
          }
        }
      });
    }
  }

  private checkUserDetailsThroughWSO2() {
    console.log("into checkUserDetailsThroughWSO2");
    this.wso2Service.getWSO2Token().subscribe((data: any) => {
      localStorage.setItem('wso2.idtoken', data.access_token);
      this.checkUserDetails();
    });
  }

  private checkUserDetails() {
    this.userService.getUserByLoginId(this.loginId)
      .subscribe(data => {
        this.paramLoggingSvc.logDebug(data + "User auth service call HttpStatusCode.");

        let authzUserInfo = data.body as AuthzUserInfo;
        let usernameForDisplay = authzUserInfo.lastName + ", " + authzUserInfo.firstName;

        if (authzUserInfo.cdmStatusCode == 200)  //Http Status code for success
        {
          authzUserInfo.isAuthenticated = true;

          if (environment.IsOktaAuthentication === "true") {
            authzUserInfo.displayName = usernameForDisplay;
          }
          else {
            authzUserInfo.displayName = this.adalService.userInfo.profile.name;
          }

          if (authzUserInfo.displayName === "") {
            authzUserInfo.displayName = authzUserInfo.lastName + ", " + authzUserInfo.firstName;
          }

          this.userService.getUserPreference(this.loginId).
            subscribe((data) => {
              localStorage.setItem("CurrentUserPreferences",
                JSON.stringify(data.body));
            });

          if (authzUserInfo.userRole.userTypeTitle === "Administrator") {

            authzUserInfo.isUserAnAdmin = true;
            this.isAdmin = true;
          }
          else {

            authzUserInfo.isUserAnAdmin = false;
            this.isAdmin = false;
          }

          this.paramLoggingSvc.logDebug("Current User title" + authzUserInfo.userRole.userTypeTitle);
          if (authzUserInfo.userRole.userTypeTitle === "Master User") {

            authzUserInfo.isMasterUser = true;
            this.isMasterUser = true;
          }
          else {

            authzUserInfo.isMasterUser = false;
            this.isMasterUser = false;
          }

          //Set the current logged-in user.
          this.CurrentUser = authzUserInfo;

          this.paramLoggingSvc.logDebug(this.CurrentUser + " - this.CurrentUser");

          this.loginSubject.next(true);
        }
        else if (authzUserInfo.cdmStatusCode == 500)  //Http Status code
        {
          authzUserInfo.isAuthenticated = false;
          this.authorizationStatusMessage = authzUserInfo.cdmFaultsBox.faultsList[0].faultText;
          this.loginSubject.next(false);
        }
        else {
          //Handle
          authzUserInfo.isAuthenticated = false;
        }

      }, err => {
        console.log("Error from CheckUserDetails() - " + err);
        this.router.navigate(['message']);
      });
  }

  logoutUser() {
    if (environment.IsOktaAuthentication === "true") {
      this.oktaAuth.signOut().then(data => {
        this.paramLoggingSvc.logDebug("User has logged out!");
      })
    }
    else {
      this.userService.logoutUser().subscribe(data => {

        this.paramLoggingSvc.logDebug("User has logged out!");
      });
    }
  }
}

